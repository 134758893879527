import $ from 'jquery';
import 'slick-carousel';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$(function(){
    $(".slick").slick({
        arrows:false,
        dots:false,
        cssEase:'linear',
        fade:true,
    })

    $('#toggler').on('click',function() {
        $('#main-menu').toggleClass('active');
    })

    $(".slick-hero-box").slick({
        arrows:false,
        dots:false,
        slidesToScroll:5,
        slidesToShow:5,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                arrows:false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false,
                arrows:false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows:false,
                
              }
            }
          ]
    })
})

